
<script>
export default {
  name: "modal-window",
  props: {
    /*show: Boolean,*/
    show1: Boolean,
    show2: Boolean,
    show3: Boolean,
  },
  methods: {
    closeModal: function () {
      this.show = false;
    }
  }
}
</script>

<template>
  <Transition name="modal">

    <div v-if="show1 || show2 || show3" class="modal-mask">
      <div class="modal-wrapper">

        <div v-if="show1" class="modal-container">
          <div class="modal-header">
            <slot name="header">default header</slot>
          </div>
          <div class="modal-body">
            <slot name="body"><b>default body</b></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button" @click="$emit('closeModal')">ОК</button>
            </slot>
          </div>
        </div>

        <div v-if="show2" class="modal-container2">
          <div class="modal-body2">
            <slot name="body"><b>default body2</b></slot>
          </div>
          <div class="modal-footer2">
            <slot name="footer2" style="text-align:center;">
              <div style="text-align:center;">
                <button class="modal-default-button" @click="$emit('closeModal')">ОК</button>
              </div>
            </slot>
          </div>
        </div>

        <div v-if="show3" class="modal-container3">
          <div class="modal-body3">
            <slot name="body"><b>default body3</b></slot>
          </div>
          <div class="modal-footer3">
            <slot name="footer3" style="text-align:center;">
              <div style="text-align:center;">
                <button class="modal-default-button" @click="$emit('closeModal')">ОК</button>
              </div>
            </slot>
          </div>
        </div>

      </div>
    </div>

  </Transition>
</template>

<style>

.modal-footer2 .footer2{
  text-align:center;
  width:100%;
}

.modal-footer3 .footer3{
  font-size:x-large;
  text-align:center;
  width:100%;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, 0.5);*/
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width:max-content;
  /*height: 150px;*/
  height: max-content+100px;
  margin: 0px auto;
  padding: 30px 30px 60px 30px;
  /*background-color: #fff;*/
  color: #ffffff;
  background-color: #ff0000;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-container2 {
  width:300px;
  /*height: 150px;*/
  height: max-content + 100px;
  margin: 0px auto;
  padding: 10px 30px 60px 30px;
  /*background-color: #fff;*/
  color: #42b983;
  background: #e9e7df;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-container3 {
  width:500px;
  /*height: 150px;*/
  height:220px;
  margin: 0px auto;
  padding: 10px 30px 60px 30px;
  /*background-color: #fff;*/
  color: #42b983;
  background: #e9e7df;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  font-style:inherit;
  font-size:large;
  color: #ffffff;
  /*color: #ff0000;*/
}

.modal-body2 {
  margin: 20px 0;
  font-style:inherit;
  font-size:large;
  color: #ffffff;
  /*color: #000000;*/
  /*color: #ff0000;*/
}

.modal-body3 {
  margin: 20px 0;
  /*text-align: center;*/
  font-style:inherit;
  font-size:large;
  color: #ffffff;
  /*color: #000000;*/
  /*color: #ff0000;*/
}

.modal-button, .modal-default-button {
  float:left;
  margin: 0px 5px;
  cursor: pointer;
}

.modal-img {
  /*float:left;*/
  /*margin: 0px 5px;*/
  border: 5px solid transparent;
  /*border:none;*/
  cursor: pointer; 
  height:50px; 
}

.modal-default-img {
  /*float:left;*/
  /*margin: 0px 5px;*/
  border: 5px solid #ffcd70;
  border-radius: 10px;
  cursor: pointer; 
  height:50px; 
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-button, .modal-default-button {
  appearance: none;
  border: 0;
  border-radius: 15px;  
  background: none;
  padding: 8px 16px;
  font-size: 16px;
}
.modal-default-button {
  background: #d1d1d1;
}


</style>