
<template>
	<div class="v-records-list-item" tabindex="0" @click="toRecordForm()" v-on:keyup.enter="toRecordForm()">
		<div class="v-records-list-item" style="width:200px; display: none;" :idaccount="record_data.idaccounts" :locked="record_data.locked" :archive="record_data.archive">{{record_data.idaccounts}}</div>
		<div class="v-records-list-item" style="width:80px;"><img v-if="record_data.locked=='1'" src="../assets/LOCK.png" height="20"><img v-if="record_data.archive=='1'" src="../assets/ARCHIVE.png" height="20"></div>
		<div class="v-records-list-item" style="width:400px;">{{record_data.date1}}</div>
		<div class="v-records-list-item" style="width:400px;">{{record_data.kp}}</div>
		<div class="v-records-list-item" style="width:400px;">{{record_data.deal}}</div>
		<div class="v-records-list-item" style="width:400px;">{{record_data.account}}</div>
    <div class="v-records-list-item" style="width:inherit;">{{record_data.customer}}</div>
    <div class="v-records-list-item" style="width:400px;">{{record_data.factory}}</div>
		<div class="v-records-list-item" style="width:400px;">{{record_data.total_discount}}</div>
		<div v-if="record_data.comment!=null" class="v-records-list-item" style="width:inherit;"><font size="2">{{record_data.comment.slice(0,250)}}</font></div>
		<div v-else class="v-records-list-item" style="width:inherit;"></div>
		<div class="v-records-list-item" style="width:800px;"><font size="2">{{record_data.lastname}} {{record_data.firstname}}</font></div> <!-- {{record_data.username}}{{record_data.client_id}}{{record_data.manager_id}} -->
		<!--div style="width:inherit; text-align: center;">{{record_data.extendinfo}}</div-->
  </div>
</template>

<script>
export default { 
  name: "v-records-list-item", 
  data() {
    return {
    };
  },
  props: {
    record_data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
  	toRecordForm () {
      //alert("toRecordForm");
      if ( this.$parent.showModal_AccDelEnterPress ){
        this.$parent.showModal_AccDelEnterPress = false;
      } else if ( this.$parent.isCursorOnRow(this.$attrs["data-row"]) ) {
        //alert( this.record_data.date1 );
        //alert( this.StringToDate ( this.record_data.date1 ) );

        let fl = true;
        if (localStorage.factorylist == undefined ) fl = false;
        if (localStorage.factorylist == "" ) fl = false;
        if ( this.record_data.factory == undefined) fl = false;
        if ( this.record_data.factory == "") fl = false;
        if ( ";"+localStorage.factorylist+";".indexOf( ";"+this.record_data.factory+";" ) == -1 ) fl = false;

        if (fl){
          let locked = this.record_data.locked;
          if ( localStorage.username != this.record_data.username ) locked = '1';
          this.$router.push( {
            name: 'record-form',
            params: {
              'account_id': this.record_data.idaccounts,
              /*'account': this.record_data.account,
              'account_id': this.record_data.idaccounts,
              'customer': this.record_data.customer,*/
              'sessionID': localStorage.sessionID,
              'account_info': {
                'account_id': this.record_data.idaccounts,
                'date1':  this.StringToDateString( this.record_data.date1 ),
                'deal': this.record_data.deal,
                'account': this.record_data.account,
                'kp': this.record_data.kp,
                'customer': this.record_data.customer,
                'factory': this.record_data.factory,

                'discount': this.record_data.discount,
                'discount_sum': this.record_data.discount_sum,
                'total': this.record_data.total,
                'total_discount': this.record_data.total_discount,

                'comment': this.record_data.comment,
                'extendinfo': this.record_data.extendinfo,
                'printinfo': this.record_data.printinfo,

                'client_id': this.record_data.client_id,
                'locked': locked,
                //'manager_id': this.record_data.manager_id,
              },
            },
            //query: {'id': this.record_data.idaccounts}
          });
        } else {
          alert("Не поддерживаемая фабрика");
        }

      } else {
        this.$parent.setCursorRow( this.$attrs["data-row"] );
      }
  	},
    StringToDateString( str ){
      //console.log( "StringToDateString на входе: ",str );
      let date = "",sep,arr,i;
      while ( typeof str == "string" ){
        if ( str.length != 10 ) break;
        i = str.indexOf("-"); if ( i != -1 ) sep = "-";
        i = str.indexOf("/"); if ( i != -1 ) sep = "/";
        i = str.indexOf("."); if ( i != -1 ) sep = ".";
        if ( sep == "" ) break;
        arr = str.split( sep ); if ( arr.length != 3 ) break;
        //console.log( "StringToDateString ",arr );
        if ( arr[0].length == 4 && arr[1].length == 2 && arr[2].length == 2 ){
          date = arr[0] + "-" + arr[1] + "-" + arr[2];
        } else if ( arr[0].length == 2 && arr[1].length == 2 && arr[2].length == 4 ){
          date = arr[2] + "-" + arr[1] + "-" + arr[0];
        }
        break;
      }
      //console.log( "StringToDate на выходе: ",date );
      return date;
    },


    /*keyboardListener(e) {
      e.preventDefault();
      switch (e.key) {
        case "Enter": {
          this.$router.push({
            name: 'record-form',
            params: {
              'account': this.record_data.account,
              'account_id': this.record_data.idaccounts,
              'customer': this.record_data.customer,
            },
          });
          break;
        }
      }
    },*/


  },

  /*mounted() {
      window.addEventListener("keydown", this.keyboardListener);
    }, 
    beforeDestroy() {
      window.removeEventListener("keydown", this.keyboardListener);
    },*/



};
</script>

<style scoped>
.v-records-list-item {
  display: flex;
  width: 100%;
  min-height: 25px; height: 25px;
  padding: 10px 15px;

  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;

  /*border: .5px solid rgba(120, 141, 152, .15);*/
  border: .1px solid rgba(120, 141, 152, .15);
  /*box-shadow: 0 1.5px 3px 0 rgba(0,0,0,.1);*/
  /*overflow: hidden;*/
  /*white-space: nowrap;*/
  /*border-collapse: collapse;*/
  outline: none;
}
.v-records-list-item:hover{
  /*background: #fff1d7;*/
  /*background: #f0f0f0;*/
  /*background: #f0f0f0;/* цвет курсора под мышью */
  cursor: pointer;
}

</style>
