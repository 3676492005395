
<template>
  <div>
    <div class="c-buttons-row--container">
      <div class="c-buttons-row">
        <tr>
          <td>
            <img src="../assets/logo3.png"  style="vertical-align: middle; width:30px; margin: 0 15px 0 0;">
          </td>
          <td><h1 style="height:20px; width: calc((100vw - 750px)/2); text-align:start;">    
          <div @click="OnAccountUser();$event.target.blur();" style="cursor: pointer;">
            <div v-if="langRUS">Журнал счетов: <b>{{user_name}}</b> <br><font size="2">Фабрики: {{factory_list}}</font></div>
            <div v-if="langENG">Account journal: <b>{{user_name}}</b> <br><font size="2">Factories: {{factory_list}}</font></div>
            <div v-if="langIT">Giornale contabile: <b>{{user_name}}</b> <br><font size="2">Fabbriche: {{factory_list}}</font></div>
          </div>
          </h1></td>

          <td v-if="user_name != account_user_name" style="width:285px;"></td>

          <td v-if="user_name == account_user_name">
            <div @click="onAccountMoveUp();$event.target.blur();" class="c-button">
              <div class="c-button__img"><img src="../assets/UP.png" height="30" title="Ctrl+Up"></div>
              <div v-if="langRUS" class="c-button__text">Вверх</div>
              <div v-if="langENG" class="c-button__text">Up</div>
              <div v-if="langIT" class="c-button__text">Su</div>
            </div>
          </td>
          <td v-if="user_name == account_user_name">
            <div @click="onAccountMoveDown();$event.target.blur();" class="c-button">
              <div class="c-button__img"><img src="../assets/DOWN.png" height="30" title="Ctrl+Down"></div>
              <div v-if="langRUS" class="c-button__text">Вниз</div>
              <div v-if="langENG" class="c-button__text">Down</div>
              <div v-if="langIT" class="c-button__text">Giù</div>
            </div>
          </td>
          <td v-if="user_name == account_user_name">
            <div @click="onAccountAddClick();$event.target.blur();" class="c-button">
              <div class="c-button__img"><img src="../assets/PLUS.png" height="30" title="Ins"></div>
              <div v-if="langRUS" class="c-button__text">Добавить</div>
              <div v-if="langENG" class="c-button__text">Add</div>
              <div v-if="langIT" class="c-button__text">Aggiungere</div>
            </div>
          </td>
          <!--
          <td>
            <div @click="onAccountEdit();$event.target.blur();" class="c-button">
              <div class="c-button__img"><img src="../assets/CHANGE.png" height="30"></div>
              <div v-if="langRUS" class="c-button__text">Изменить</div>
              <div v-if="langENG" class="c-button__text">Change</div>
              <div v-if="langIT" class="c-button__text">Modifica</div>
            </div>
          </td>
          -->
          <td v-if="user_name == account_user_name">
            <div @click="onAccountDeleteClick();$event.target.blur();" class="c-button">
              <div class="c-button__img"><img src="../assets/MINUS.png" height="30" title="Shift+Del"></div>
              <div v-if="langRUS" class="c-button__text">Удалить</div>
              <div v-if="langENG" class="c-button__text">Delete</div>
              <div v-if="langIT" class="c-button__text">Eliminare</div>
            </div>
          </td>
          <td v-if="user_name == account_user_name">
            <div @click="onAccountCopy();$event.target.blur();" class="c-button">
              <div class="c-button__img"><img src="../assets/COPY.png" height="30" title="F9"></div>
              <div v-if="langRUS" class="c-button__text">Скопировать</div>
              <div v-if="langENG" class="c-button__text">Copy</div>
              <div v-if="langIT" class="c-button__text">Copia</div>
            </div>
          </td>
          <td>
            <div v-if="locked" @click="onAccountLockUnlock($event);$event.target.blur();" class="c-button">
              <div class="c-button__img"><img src="../assets/LOCK.png" height="30"></div>
              <div v-if="langRUS" class="c-button__text">Разблокировать</div>
              <div v-if="langENG" class="c-button__text">Unlock</div>
              <div v-if="langIT" class="c-button__text">Bloccare</div>
            </div>
            <div v-else @click="onAccountLockUnlock($event);$event.target.blur();" class="c-button">
              <div class="c-button__img"><img src="../assets/UNLOCK.png" height="30"></div>
              <div v-if="langRUS" class="c-button__text">Заблокировать</div>
              <div v-if="langENG" class="c-button__text">Lock</div>
              <div v-if="langIT" class="c-button__text">Bloccare</div>
            </div>
          </td>
          <td v-if="user_name == account_user_name">
            <div v-if="archive" @click="onAccountArcUnarc($event);$event.target.blur();" class="c-button">
              <div class="c-button__img"><img src="../assets/ARCHIVE.png" height="30" title="F9"></div>
              <div v-if="langRUS" class="c-button__text">Из_архива</div>
              <div v-if="langENG" class="c-button__text">From the archive</div>
              <div v-if="langIT" class="c-button__text">All'archivio</div>
            </div>
            <div v-else @click="onAccountArcUnarc($event);$event.target.blur();" class="c-button">
              <div class="c-button__img"><img src="../assets/UNARCHIVE.png" height="30"></div>
              <div v-if="langRUS" class="c-button__text">В_архив</div>
              <div v-if="langENG" class="c-button__text">To the archive</div>
              <div v-if="langIT" class="c-button__text">All'archivio</div>
            </div>
          </td>

          <td v-if="user_name != account_user_name" style="width:285px;"></td>

          <td>
            <h1 style="width: calc((100vw - 750px)/2); text-align: end;">
              <img @click="OnRus();$event.target.blur();" src="../assets/rus.png" height="20" style="cursor: pointer;" title="Русский">&nbsp;
              <img @click="OnEng();$event.target.blur();" src="../assets/eng.png" height="20" style="cursor: pointer;" title="English">&nbsp;
              <img @click="OnIt();$event.target.blur();" src="../assets/it.png" height="20" style="cursor: pointer;" title="Italiano">&nbsp;
              <img src="../assets/empty.png" height="20">
            </h1>
          </td>
        </tr>
      </div>
      <div class="c-buttons-row--container-after">
      </div>
    </div>

    <div class="c-container">
      <!--<div style="height:10px"></div>-->
      <!--h1>Журнал счетов, пользователь {{  }}</h1--> <!-- {{this.$children[this.cursor_pos.row].record_data.idaccounts}} , строка {{cursor_pos.row}} -->
      <!-- VUE JS - Директивы https://v3.ru.vuejs.org/ru/api/directives.html#v-for -->
      <div ref="table" class="v-records-table" id="v-records-table">
        <!--<thead> -->
          <div v-if="langRUS" class="v-records-table__header">
            <div class="v-records-list-item" style="width:200px; display: none;">id</div>
            <div class="v-records-list-item" style="width:80px;" v-on:click="columnSort('lock')">{{vmode_lock}}</div>
            <div class="v-records-list-item" style="width:400px;" v-on:click="columnSort('date')">Дата {{vmode_date}}</div>
            <div class="v-records-list-item" style="width:400px;" v-on:click="columnSort('kp')">КП {{vmode_kp}}</div>
            <div class="v-records-list-item" style="width:400px;" v-on:click="columnSort('deal')">Сделка {{vmode_deal}}</div>
            <div class="v-records-list-item" style="width:400px;" v-on:click="columnSort('acc')">Счет {{vmode_acc}}</div>
            <div class="v-records-list-item" v-on:click="columnSort('cust')">Контрагент {{vmode_cust}}</div>
            <div class="v-records-list-item" style="width:400px;" v-on:click="columnSort('fact')">Фабрика {{vmode_fact}}</div>
            <div class="v-records-list-item" style="width:400px;" v-on:click="columnSort('summ')">Сумма {{vmode_summ}}</div>
            <div class="v-records-list-item" v-on:click="columnSort('comm')">Комментарий {{vmode_comm}}</div>
            <div class="v-records-list-item" style="width:800px;" v-on:click="columnSort('usr')">Менеджер {{vmode_usr}}<div style="width: 10px;"></div><img src="../assets/filter.png" height="20" v-on:click="managerFilter();$event.stopPropagation();$event.preventDefault();" style="cursor: pointer;">
              <div id="modal_manager" class="v-records-list-manager"
                style="display:none; padding: 15px; padding-right: 100px;  overflow-y:scroll; width: max-content; max-height: calc(100vh - 500px);"
                v-on:click="filterChange($event);$event.stopPropagation();">
                <div style="padding: 3px;"><input type="checkbox" id="mainbase" name="filter" /><label>Рабочая база</label></div>
                <div style="padding: 3px;"><input type="checkbox" id="arcbase" name="filter" /><label>Архив</label></div>
                <div v-for="(manager, manager_index) in managers" :key="manager_index" style="padding: 3px;">
                  <div><input type="radio" id="manager" name="filter" :lastname="manager.lastname" :firstname="manager.firstname"/><label for="manager">{{manager.lastname}} {{manager.firstname}}</label></div>
                </div>
              </div>
            </div>
            <!--div class="v-records-list-item">Дополнительно</div-->
          </div>
          <div v-if="langENG" class="v-records-table__header">
            <div class="v-records-list-item" style="width:200px; display: none;">id</div>
            <div class="v-records-list-item" style="width:400px;">Date</div>
            <div class="v-records-list-item" style="width:400px;">Commercial offer</div>
            <div class="v-records-list-item" style="width:400px;">Deal</div>
            <div class="v-records-list-item" style="width:400px;">Account</div>
            <div class="v-records-list-item">Customer</div>
            <div class="v-records-list-item" style="width:400px;">Factory</div>
            <div class="v-records-list-item" style="width:400px;">Sum</div>
            <div class="v-records-list-item">Comment</div>
            <div class="v-records-list-item" style="width:800px;">Manager</div>
            <!--div class="v-records-list-item">Дополнительно</div-->
          </div>
          <div v-if="langIT" class="v-records-table__header">
            <div class="v-records-list-item" style="width:200px; display: none;">id</div>
            <div class="v-records-list-item" style="width:400px;">Data</div>
            <div class="v-records-list-item" style="width:400px;">Offerta commerciale</div>
            <div class="v-records-list-item" style="width:400px;">Affare</div>
            <div class="v-records-list-item" style="width:400px;">Controllo</div>
            <div class="v-records-list-item">Controparte</div>
            <div class="v-records-list-item" style="width:400px;">Fabbrica</div>
            <div class="v-records-list-item" style="width:400px;">Somma</div>
            <div class="v-records-list-item">Un commento</div>
            <div class="v-records-list-item" style="width:800px;">Manager</div>
            <!--div class="v-records-list-item">Дополнительно</div-->
          </div>
        <!--</thead>-->
        <!--<tbody>-->
          <!-- onAccountRowClick( record.idaccount, $event.target);-->
          <div>
          <v-records-list-item v-for="(record, index) in accounts"
            class="v-records-list-item"
            :key="index" 
            :record_data="record"
            :data-row="index"
            ></v-records-list-item> <!--:class="{'is-active' :isCursorOnRow(index)}"   :class="{ isRefresh }"-->
          </div>
        <!--</tbody>-->
      </div>
    </div>

    <!--    <Teleport to="body" id="modal-template" name="modal"> -->
    <modal ref="modal" :show1="showModal_AccDel" @close="showModal_AccDel=false">
      <template #header><div v-if="langRUS">Предупреждение</div><div v-if="langENG">Warning</div><div v-if="langIT">Avvertimento</div></template>
      <template #body>
        <div v-if="langRUS">Вы действительно хотите удалить счет?<br>Восстановить его будет невозможно.</div>
        <div v-if="langENG">Are you sure you want to delete your account?<br>It will be impossible to restore it.</div>
        <div v-if="langIT">Sei sicuro di voler eliminare il tuo account?<br>Sarà impossibile ripristinarlo.</div>
      </template>
      <template #footer>
        <button id="button_del" :class="{'modal-default-button':showModal_AccDelPos==0,'modal-button':showModal_AccDelPos==1}" @click="onAccountDelete();">
          <div v-if="langRUS">Удалить</div><div v-if="langENG">Delete</div><div v-if="langIT">Eliminare</div>            
        </button>
        <button id="button_cancel" :class="{'modal-default-button':showModal_AccDelPos==1,'modal-button':showModal_AccDelPos==0}" @click="closeModal();">
          <div v-if="langRUS">Отказаться</div><div v-if="langENG">Cancel</div><div v-if="langIT">Rifiutare</div>                        
        </button>
      </template>
    </modal>
    <!--    </Teleport> -->

    <modal ref="modal" :show2="showModal_FSelect" @close="showModal_FSelect=false" style="text-align:center;">
      <template #body><div v-if="langRUS">Выберите фабрику:</div><div v-if="langENG">Select factory:</div><div v-if="langIT">Seleziona fabbrica:</div><br>
        <img src="../assets/UNION.png" @click="onAccountAdd('Union');" :class="{'modal-default-img':showModal_FSelectPosV==0,'modal-img':showModal_FSelectPosV!=0}"><br>
        <img src="../assets/GHIZZI_BENATTI.png" @click="onAccountAdd('GHIZZI & BENATTI');" :class="{'modal-default-img':showModal_FSelectPosV==1,'modal-img':showModal_FSelectPosV!=1}"><br>
        <!--
        <img src="../assets/LONGHI.png" @click="onAccountAdd('LONGHI');" :class="{'modal-default-img':showModal_FSelectPosV==2,'modal-img':showModal_FSelectPosV!=2}"><br>
        <img src="../assets/LEGNOFORM.png" @click="onAccountAdd('LEGNOFORM');" :class="{'modal-default-img':showModal_FSelectPosV==3,'modal-img':showModal_FSelectPosV!=3}"><br>
        <img src="../assets/BLUINTERNI.png" @click="onAccountAdd('BLUINTERNI');" :class="{'modal-default-img':showModal_FSelectPosV==4,'modal-img':showModal_FSelectPosV!=4}"><br>
        -->
      </template>
      <template #footer2>
        <button id="button_select" :class="{'modal-default-button':showModal_FSelectPos==0,'modal-button':showModal_FSelectPos==1}" @click="onFactorySelect();">
          <div v-if="langRUS">Добавить</div><div v-if="langENG">Add</div><div v-if="langIT">Aggiungere</div></button>
        <button id="button_cancel" :class="{'modal-default-button':showModal_FSelectPos==1,'modal-button':showModal_FSelectPos==0}" @click="closeModal();">
          <div v-if="langRUS">Отказаться</div><div v-if="langENG">Cancel</div><div v-if="langIT">Rifiutare</div></button>
      </template>
    </modal>

  </div>
</template>

<script>

import modal from './modal'
import vRecordsListItem from "./v-records-list-item";
import {mapActions, mapState} from 'vuex';

export default {
  name: "v-records-list",
  components: {
    vRecordsListItem,
    modal,
  },
  data() {
    return {
      //isRefresh: 0,
      showModal_AccDel: false, // DDAdd
      showModal_AccDelPos: 1, // DDAdd
      showModal_FSelect: false, // DDAdd
      showModal_FSelectPos: 0, // DDAdd
      showModal_FSelectPosV: 0, // DDAdd
      showModal_AccDelEnterPress: false,
      langRUS:true,
      langENG:false,
      langIT:false,
      cursor_pos: { // DDAdd
        row: 0,
        min: 0,
        max: 0,
        active: false,
        increaseRow() { // DDAdd
          if(this.row >= this.max) this.row = this.max;
          else this.row++;
          this.showCursor();
        },
        decreaseRow() { // DDAdd
          if(this.row <= this.min) this.row = this.min;
          else this.row--;
          this.showCursor();
        },
        FirstRow() { // DDAdd
          this.row = this.min;
          this.showCursor();
        },
        LastRow() { // DDAdd
          this.row = this.max;
          this.showCursor();
        },

        showCursor() {
          // установить выделение на нужную ячейку
          let elements = document.querySelectorAll(".is-active");
          if (elements.length > 0) {
            for (let elem of elements) { elem.classList.remove("is-active"); }
          }
          const dataRowEl = document.querySelector(`[data-row="${this.row}"]`);
          if (dataRowEl != null) dataRowEl.classList.add("is-active");

          // прокрутить окно
          const table = document.getElementById("v-records-table");
          if (table != null && dataRowEl != null){
            //const scrollY = -(this.$refs.table.getBoundingClientRect().bottom - dataRowEl.getBoundingClientRect().bottom) + 10;
            let scrollY = -(table.getBoundingClientRect().bottom - dataRowEl.getBoundingClientRect().bottom) + 10;
            if (scrollY > 0) table.scrollBy(0, scrollY);

            //const scrollY = -this.$refs.table.getBoundingClientRect().top + this.sticky_offsets.y + dataRowEl.getBoundingClientRect().top;
            scrollY = -table.getBoundingClientRect().top + this.sticky_offsets.y + dataRowEl.getBoundingClientRect().top;
            if(scrollY < 0) table.scrollBy(0, scrollY);
          }

          if (dataRowEl != null) dataRowEl.focus();

          //dataCellEl.focus();

        },

        sticky_offsets: { // DDAdd
        y_offset: 0,
        get y() {
          if(!this.y_offset) this.y_offset = -document.querySelector(".v-records-table__header").offsetHeight;
          return this.y_offset;
        }
      }




      },
      sticky_offsets: { // DDAdd
        y_offset: 0,
        get y() {
          if(!this.y_offset) this.y_offset = -document.querySelector(".v-records-table__header").offsetHeight;
          return this.y_offset;
        }
      },
      vmode:      "date-",
      vmode_date: "↑",
      vmode_kp:   "",
      vmode_deal: "",
      vmode_acc:  "",
      vmode_cust: "",
      vmode_fact: "",
      vmode_summ: "",
      vmode_comm: "",
      vmode_usr:  "",
      vmode_lock:  "",
      new_idaccount: -1,
      locked: false,
      archive: false,
      account_user_name: "",

      /*filter:{
        lastname:"",
        firstname:"",
        main:"",
        archive:"",
      },*/
    };
  },

  props: {
    /*account: {
      type: String,
      default: () => "0",
    },*/
    account_id: {
      type: Number,
      default: () => 0,
    },
    /*customer: {
      type: String,
      default: () => "",
    }*/
  },

  computed: {
    ...mapState([
        "accounts",
        "user_name",
        "factory_list",
        "managers",
        //"new_idaccount",
      ])
  },
  methods: {
    //Асинхронный запрос к серверу через action в сторе
    ...mapActions([
      "FETCH_ACCOUNTS",
      "FETCH_MANAGERS",
      "ADD_NEW_ACCOUNT",
      "DELETE_ACCOUNT",
      "COPY_ACCOUNT",
      "SEND_MAIL",
      "SET_LANGUAGE",
      "GET_LANGUAGE",
      "REFRESH_USER_DATA",
      "SET_ACCOUNT_STATE",
    ]),



    checkLocked(){
      if ( this.user_name != this.account_user_name ) { alert("В этом режиме возможен только просмотр журнала счетов"); return false; } else { return true; }
    },



    checkAccount(){
      let elements = document.querySelectorAll(".is-active");
      if (elements.length > 0) {
        //const dataRowEl = document.querySelector(`[data-row="${this.row}"]`);
        const dataRowEl = elements[0];
        if (dataRowEl != null) {
          let locked = dataRowEl.children[0].getAttribute("locked");
          if ( locked == '1' ) this.locked = true; else this.locked = false;
          let archive = dataRowEl.children[0].getAttribute("archive");
          if ( archive == '1' ) this.archive = true; else this.archive = false;
        }
      }
    },


    /*forceRerender() {
      this.isRefresh += 1;  
    },*/

    async onAccountLockUnlock(e){
      let elements = document.querySelectorAll(".is-active");
      if (elements.length > 0) {
        const dataRowEl = elements[0];
        if (dataRowEl != null) {
          let idaccount = dataRowEl.children[0].getAttribute("idaccount");
          await this.SET_ACCOUNT_STATE({ thisobj:this, idaccounts:idaccount, mode:"lock", e:e });
        }
      }
    },

    async onAccountArcUnarc(e){
      let elements = document.querySelectorAll(".is-active");
      if (elements.length > 0) {
        const dataRowEl = elements[0];
        if (dataRowEl != null) {
          let idaccount = dataRowEl.children[0].getAttribute("idaccount");
          await this.SET_ACCOUNT_STATE({ thisobj:this, idaccounts:idaccount, mode:"archive", e:e });
        }
      }
    },

    


    columnSort( mode ){
      this.vmode_date = ""; this.vmode_kp = ""; this.vmode_deal = ""; this.vmode_acc = ""; this.vmode_cust = ""; this.vmode_fact = ""; this.vmode_summ = "";
      this.vmode_comm = ""; this.vmode_usr = ""; this.vmode_lock = "";
      switch ( mode ){
        case 'date': if ( this.vmode == 'date+' ) { this.vmode = 'date-'; this.vmode_date = "↑"; } else { this.vmode = 'date+'; this.vmode_date = "↓"; } break;
        case 'kp':   if ( this.vmode == 'kp+' ) { this.vmode = 'kp-'; this.vmode_kp = "↑"; } else { this.vmode = 'kp+'; this.vmode_kp = "↓"; } break;
        case 'deal': if ( this.vmode == 'deal+' ) { this.vmode = 'deal-'; this.vmode_deal = "↑"; } else { this.vmode = 'deal+'; this.vmode_deal = "↓"; } break;
        case 'acc':  if ( this.vmode == 'acc+' ) { this.vmode = 'acc-'; this.vmode_acc = "↑"; } else { this.vmode = 'acc+'; this.vmode_acc = "↓"; } break;
        case 'cust': if ( this.vmode == 'cust+' ) { this.vmode = 'cust-'; this.vmode_cust = "↑"; } else { this.vmode = 'cust+'; this.vmode_cust = "↓"; } break;
        case 'fact': if ( this.vmode == 'fact+' ) { this.vmode = 'fact-'; this.vmode_fact = "↑"; } else { this.vmode = 'fact+'; this.vmode_fact = "↓"; } break;
        case 'summ': if ( this.vmode == 'summ+' ) { this.vmode = 'summ-'; this.vmode_summ = "↑"; } else { this.vmode = 'summ+'; this.vmode_summ = "↓"; } break;
        case 'comm': if ( this.vmode == 'comm+' ) { this.vmode = 'comm-'; this.vmode_comm = "↑"; } else { this.vmode = 'comm+'; this.vmode_comm = "↓"; } break;
        case 'usr':  if ( this.vmode == 'usr+' ) { this.vmode = 'usr-'; this.vmode_usr = "↑"; } else { this.vmode = 'usr+'; this.vmode_usr = "↓"; } break;
        case 'lock':  if ( this.vmode == 'lock+' ) { this.vmode = 'lock-'; this.vmode_lock = "↑"; } else { this.vmode = 'lock+'; this.vmode_lock = "↓"; } break;
      }
      let filter_arr = getFilter();
      //console.log( filter_arr );
      this.FETCH_ACCOUNTS( { thisobj:this, mode:1, filter:filter_arr } );
    },

    setCursorRow(row) { // DDAdd
      this.cursor_pos.row = row;
      this.cursor_pos.showCursor();
      this.checkAccount();
    },
    setCursorID( idaccounts ) { // DDAdd
      let index = this.state.accounts.findIndex((account) => account.idaccounts == idaccounts);
      if ( index != -1 ){
        this.setCursorRow( index );
      }
      //this.cursor_pos.showCursor();
    },
    isCursorOnRow(row) {
      if(row == this.cursor_pos.row) return true;
    },

    reloadPageByCursor() { // DDAdd
      this.cursor_pos.row++;
      this.cursor_pos.row--;
      this.cursor_pos.showCursor();
      this.checkAccount();
    },
    keyboardListener(e) {
      e.preventDefault();
      this.showModal_AccDelEnterPress = false;
      switch (e.key) {
        case "ArrowUp": { // DDAdd
          e.preventDefault();
          if (this.showModal_AccDel) break;
          if (this.showModal_FSelect){
            this.showModal_FSelectPosV--;
            if (this.showModal_FSelectPosV<0 ) this.showModal_FSelectPosV=0;
            break;
          }
          this.cursor_pos.decreaseRow();
          this.checkAccount();

          /*const dataRowEl = this.$el.querySelector(`[data-row="${this.cursor_pos.row}"]`);
          const scrollY = -this.$refs.table.getBoundingClientRect().top + this.sticky_offsets.y + dataRowEl.getBoundingClientRect().top;
          if(scrollY < 0) this.$refs.table.scrollBy(0, scrollY);
          dataRowEl.focus(); */
          break;
        }
        case "ArrowDown": { // DDAdd
          e.preventDefault();
          if (this.showModal_AccDel) break;
          if (this.showModal_FSelect){
            this.showModal_FSelectPosV++;
            if (this.showModal_FSelectPosV>1 ) this.showModal_FSelectPosV=1;
            break;
          }
          this.cursor_pos.increaseRow();
          this.checkAccount();

          /*const dataRowEl = this.$el.querySelector(`[data-row="${this.cursor_pos.row}"]`);
          const scrollY = -(this.$refs.table.getBoundingClientRect().bottom - dataRowEl.getBoundingClientRect().bottom) + 10;
          if (scrollY > 0) this.$refs.table.scrollBy(0, scrollY);*/

          //dataRowEl.focus(); 
          break;
        }
        case "ArrowLeft": { // DDAdd
          e.preventDefault();
          if (!this.showModal_AccDel && !this.showModal_FSelect ) break;
          if (this.showModal_AccDel) this.showModal_AccDelPos = 0;
          if (this.showModal_FSelect) this.showModal_FSelectPos = 0;
          break;
        }
        case "ArrowRight": { // DDAdd
          e.preventDefault();
          if (!this.showModal_AccDel && !this.showModal_FSelect) break;
          if (this.showModal_AccDel) this.showModal_AccDelPos = 1;
          if (this.showModal_FSelect) this.showModal_FSelectPos = 1;
          break;
        }
        case "Home": { // DDAdd
          e.preventDefault();
          if (this.showModal_AccDel) { this.showModal_AccDelPos = 0; break; }
          if (this.showModal_FSelect) { this.showModal_FSelectPos = 0; break; }
          this.cursor_pos.FirstRow();

          /*const dataRowEl = this.$el.querySelector(`[data-row="${this.cursor_pos.row}"]`);
          const scrollY = -this.$refs.table.getBoundingClientRect().top + this.sticky_offsets.y + dataRowEl.getBoundingClientRect().top;
          if(scrollY < 0) this.$refs.table.scrollBy(0, scrollY);
          dataRowEl.focus(); */
          break;
        }
        case "End": { // DDAdd
          e.preventDefault();
          if (this.showModal_AccDel) { this.showModal_AccDelPos = 1; break; }
          if (this.showModal_FSelect) { this.showModal_FSelectPos = 1; break; }
          this.cursor_pos.LastRow();

          /*const dataRowEl = this.$el.querySelector(`[data-row="${this.cursor_pos.row}"]`);
          const scrollY = -(this.$refs.table.getBoundingClientRect().bottom - dataRowEl.getBoundingClientRect().bottom) + 10;
          if (scrollY > 0) this.$refs.table.scrollBy(0, scrollY);
          dataRowEl.focus(); */
          break;
        }
        case "PageUp": { // DDAdd
          e.preventDefault();
          if (this.showModal_AccDel) break;
          if (this.showModal_FSelect){ this.showModal_FSelectPosV=0; break; }
          let dataRowEl, scrollY, trueFlag = true, moveFlag = false, count = 0;

          do {
            count++; if (count>20) break;
            dataRowEl = this.$el.querySelector(`[data-row="${this.cursor_pos.row}"]`);
            scrollY = dataRowEl.getBoundingClientRect().top - ( this.$refs.table.getBoundingClientRect().top - this.sticky_offsets.y );
            if( scrollY <= 3 ){ break; }
            this.cursor_pos.decreaseRow(); moveFlag = true; // переместиться наверх видимой области
          } while ( trueFlag );

          if ( !moveFlag ){
            let numStep = Math.floor( ( this.$refs.table.getBoundingClientRect().height + this.sticky_offsets.y ) / dataRowEl.getBoundingClientRect().height );
            for( let i = 0; i<numStep; i++ ) { this.cursor_pos.decreaseRow(); } // переместиться на страницу вверх
            scrollY = -dataRowEl.getBoundingClientRect().height * numStep;
          }
          this.$refs.table.scrollBy(0, scrollY);
          dataRowEl.focus(); break;
        }
        case "PageDown": { // DDAdd
          e.preventDefault();
          if (this.showModal_AccDel) break;
          if (this.showModal_FSelect){ this.showModal_FSelectPosV=1; break; }
          let dataRowEl, scrollY, trueFlag = true, moveFlag = false, count = 0;
          do {
            count++; if (count>20) break;
            dataRowEl = this.$el.querySelector(`[data-row="${this.cursor_pos.row}"]`);
            scrollY = dataRowEl.getBoundingClientRect().top + dataRowEl.getBoundingClientRect().height - 30 -
                     ( this.$refs.table.getBoundingClientRect().top + this.sticky_offsets.y + this.$refs.table.getBoundingClientRect().height );
            if( scrollY > -5 ){ break; }
            this.cursor_pos.increaseRow(); moveFlag = true; // переместиться вниз видимой области
          } while ( trueFlag );
          if ( !moveFlag ){
            let numStep = Math.floor((this.$refs.table.getBoundingClientRect().height+this.sticky_offsets.y) / dataRowEl.getBoundingClientRect().height);
            for( let i = 0; i<numStep; i++ ) { this.cursor_pos.increaseRow(); } // переместиться на страницу вниз
            scrollY = dataRowEl.getBoundingClientRect().height * numStep;
          }
          this.$refs.table.scrollBy(0, scrollY);
          dataRowEl.focus(); break;
        }
        case "Insert": { // DDAdd
          e.preventDefault();
          if (this.showModal_AccDel) break;
          this.onAccountAddClick();
          break;
        }
        case "Delete": { // DDAdd
          e.preventDefault();
          if ( !this.checkLocked() ) break;
          if (this.showModal_AccDel) break;
          if (e.shiftKey && e.ctrlKey) { this.onAccountDelete(); break; }
          if (e.shiftKey) this.showModal_AccDel = true;
          break;
        }
        case "F9": { // DDAdd
          e.preventDefault();
          if (this.showModal_AccDel) break;
          this.onAccountCopy();
          break;
        }
        case "Enter": { // DDAdd
          e.preventDefault();
          if (this.showModal_AccDel) {
            if (this.showModal_AccDelPos == 0) this.onAccountDelete();
            this.showModal_AccDelEnterPress = true;
            this.closeModal(); break;
          }

          if (this.showModal_FSelect) {
            if (this.showModal_FSelectPos == 0 && this.showModal_FSelectPosV == 0) this.onAccountAdd("Union");
            if (this.showModal_FSelectPos == 0 && this.showModal_FSelectPosV == 1) this.onAccountAdd("GHIZZI & BENATTI");
            /*if (this.showModal_FSelectPos == 0 && this.showModal_FSelectPosV == 1) this.onAccountAdd("LONGHI");
            if (this.showModal_FSelectPos == 0 && this.showModal_FSelectPosV == 1) this.onAccountAdd("LEGNOFORM");
            if (this.showModal_FSelectPos == 0 && this.showModal_FSelectPosV == 1) this.onAccountAdd("BLUINTERNI");*/
            this.showModal_AccDelEnterPress = true;
            this.closeModal(); break;
          }

          //this.toRecordForm();
          //this.$children[this.cursor_pos.row].toRecordForm();
          break;
        }
        case "Escape": { // DDAdd
          e.preventDefault();
          if (this.showModal_AccDel) this.closeModal();
          if (this.showModal_FSelect) this.closeModal();
          break;
        }
      }
    },

    async onAccountAddClick() { // DDAdd 
      if ( !this.checkLocked() ) return;
      let factorylist = localStorage.factorylist;
      if ( factorylist == undefined || factorylist == "" ){ alert("Войдите в учетную запись заново."); return; }
      if ( factorylist.indexOf(";") != -1){
        this.showModal_FSelect = true;
      } else {
        this.onAccountAdd( factorylist );
      }
      return;
    },

    async onAccountAdd( factory ) { // DDAdd 

      //let str = await new Promise(resolve => this.GET_FACTORY_LIST( resolve ));
      //let str = this.factory_list;
      //this.SEND_MAIL();

      this.closeModal();
      await this.ADD_NEW_ACCOUNT( { thisobj:this, factory:factory/*, vmode:this.vmode*/ } );
      
      /*this.cursor_pos.max ++;
      this.cursor_pos.LastRow();*/

      let filter_arr = getFilter();
      //console.log( filter_arr );
      await this.FETCH_ACCOUNTS( { thisobj:this, mode:1, filter:filter_arr } );
      this.$forceUpdate();


      //this.$nextTick(function() {
        //this.reloadPageByCursor();
        /*const dataRowEl = this.$el.querySelector(`[data-row="${this.cursor_pos.row-1}"]`);
        const scrollY = -(this.$refs.table.getBoundingClientRect().bottom - dataRowEl.getBoundingClientRect().bottom) + 10;
        if (scrollY > 0) this.$refs.table.scrollBy(0, scrollY);
        this.$refs.table.scrollBy(0, 30);
        this.cursor_pos.showCursor();*/
      //});
    },

    onAccountMoveUp(){
      alert("Перемещение счетов вверх и вниз еще не реализовано.");
    },
    onAccountMoveDown(){
      alert("Перемещение счетов вверх и вниз еще не реализовано.");
    },
    async onAccountCopy(){
      if ( !this.checkLocked() ) return;
      let dataRowEl = this.$el.querySelector(`[data-row="${this.cursor_pos.row}"]`);
      const dataRowElid = dataRowEl.querySelector("[idaccount]");
      if (dataRowElid != null ){
        await this.COPY_ACCOUNT( dataRowElid.innerText );

        this.cursor_pos.max ++;
        this.cursor_pos.LastRow();

        dataRowEl = this.$el.querySelector(`[data-row="${this.cursor_pos.row-1}"]`);
        const scrollY = -(this.$refs.table.getBoundingClientRect().bottom - dataRowEl.getBoundingClientRect().bottom) + 10;
        if (scrollY > 0) this.$refs.table.scrollBy(0, scrollY);
        this.$refs.table.scrollBy(0, 30);
        this.cursor_pos.showCursor();
      }

    },
    OnRus(){
      this.langRUS = true; this.langENG = false; this.langIT = false;
      this.SET_LANGUAGE("rus");
    },
    OnEng(){
      this.langRUS = false; this.langENG = true; this.langIT = false;
      this.SET_LANGUAGE("eng");
    },
    OnIt(){
      this.langRUS = false; this.langENG = false; this.langIT = true;
      this.SET_LANGUAGE("it");
    },

    onAccountDeleteClick() {
      if ( !this.checkLocked() ) return;
      if (!this.showModal_AccDel) this.showModal_AccDel = true;
    },

    /*async*/ onAccountDelete() {

      if ( !this.checkLocked() ) return;
      const dataRowEl = this.$el.querySelector(`[data-row="${this.cursor_pos.row}"]`);
      const dataRowElid = dataRowEl.querySelector("[idaccount]");
      if (dataRowElid != null ){
        /*await*/ this.DELETE_ACCOUNT(dataRowElid.innerText);
        this.cursor_pos.max --;
        this.cursor_pos.showCursor();
        //this.forceRerender();
      }
      this.showModal_AccDel = false;

    },
    closeModal() {
      this.showModal_AccDel = false;
      this.showModal_AccDelPos = 1;
      this.showModal_FSelect = false;
      this.showModal_FSelectPos = 0;
      this.showModal_FSelectPosV = 0;
    },

    OnAccountUser(){
      this.$router.push( { 
          name: 'login',
        })
    },

    filterChange(e){
      let filter = document.getElementsByName("filter"), filter_arr = [];
      if ( filter != undefined ){
        for ( let i=0; i<filter.length; i++ ){
          if ( filter[i].checked ) filter_arr.push( { id:filter[i].id, lastname:filter[i].getAttribute("lastname"), firstname:filter[i].getAttribute("firstname") } );
        }
        localStorage.filter = JSON.stringify( filter_arr );
        //console.log( filter_arr );
        this.FETCH_ACCOUNTS( { thisobj:this, mode:1, filter:filter_arr } );
      }
    },

    async managerFilter(){
      const manager = document.getElementById("modal_manager");
      if ( manager != undefined ) manager.style.display = manager.style.display == "none" ? "block" : "none";
      let filter = document.getElementsByName("filter"), filter_arr = [];
      if ( filter != undefined ){
        let filter_arr = getFilter();
        for ( let i=0; i<filter.length; i++ ){
          let lastname = filter[i].getAttribute("lastname");
          let firstname = filter[i].getAttribute("firstname");
          let id = filter[i].getAttribute("id");
          for ( let j=0; j<filter_arr.length; j++ ){
            if ( filter_arr[j].id == id && filter_arr[j].lastname == lastname && filter_arr[j].firstname == firstname ){
              filter[i].checked = true;
            }
          }
        }
        localStorage.filter = JSON.stringify( filter_arr );
        //console.log( filter_arr );
        this.FETCH_ACCOUNTS( { thisobj:this, mode:1, filter:filter_arr } );
      }









    },


  },
  async mounted() {
    let lang = await new Promise(resolve => this.GET_LANGUAGE( resolve ));
    if (lang == "rus") this.OnRus();
    if (lang == "eng") this.OnEng();
    if (lang == "it") this.OnIt();

    let filter_arr = getFilter();
    //console.log( filter_arr );
    this.FETCH_ACCOUNTS( { thisobj:this, mode:0, filter:filter_arr } );
    //let rc = await new Promise(resolve => this.FETCH_MANAGERS( resolve/*, this*/ ));
    this.FETCH_MANAGERS();
    this.REFRESH_USER_DATA();

    window.addEventListener("keydown", this.keyboardListener);

    this.$nextTick(function() {
      if (!this.cursor_pos.max && this.accounts.length) {
        let idaccount = this.$store.getters.GET_ACCOUNT_ID;
        let index = this.accounts.findIndex((account) => account.idaccounts == idaccount);
        //if ( this.accounts[index].locked == '1' ) this.locked = true; else this.locked = false;
        //if ( this.accounts[index].archive == '1' ) this.archive = true; else this.archive = false;
        this.cursor_pos.max = this.accounts.length-1;
        this.cursor_pos.row = index;
        this.cursor_pos.showCursor();
      }
    });
    this.showModal_AccDelEnterPress = true;
    this.checkAccount();
  },
  updated(){ // DDAdd
    if ( this.new_idaccount != undefined && this.new_idaccount != -1 ){ // перейти на новую строку после добавления
      let index = this.accounts.findIndex((account) => account.idaccounts == this.new_idaccount);
      this.setCursorRow( index );
      this.new_idaccount = -1;
    }
    if (!this.cursor_pos.max && this.accounts.length) {
      this.cursor_pos.max = this.accounts.length-1;
    }
    this.cursor_pos.showCursor();
    this.REFRESH_USER_DATA();
    this.checkAccount();
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keyboardListener);
  },
};

function getFilter(){
  if ( localStorage.filter != undefined && localStorage.filter != "" ){
    return JSON.parse( localStorage.filter );
  } 
  return JSON.parse( '[{"id":"mainbase","lastname":null,"firstname":null}]' );
}

</script>

<style type="text/css">

.v-records-table {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 130px); /* 200 */
  width:100%;
  /*box-shadow: 0 0 2px 0 rgba(0,0,0,.1),0 2px 2px 0 rgba(0,0,0,.2);*/
}
.v-records-table__header {
  position: sticky;
  top: 0;
  /*background: #d4eaf8;*/
  /*background: #bbbbbb; 
  /*background: #d1d1d1;*//* цвет заголовка таблицы */
  background: #e9e7df;


  z-index: 3;
  display: flex;
  /*width:100%;*/
  min-height: 25px; height: 25px;
  padding: 10px 15px;

  justify-content: center;
  align-items: center;
  align-content: center;

  border: .5px solid rgba(120, 141, 152, .15);
  /*box-shadow: 0 1.5px 3px 0 rgba(0,0,0,.1);*/

  /*overflow: hidden;*/
  width:inherit;
  /*white-space: nowrap;*/
  /*border-collapse: collapse;*/
}
.v-records-list-item {
  display: flex;
  width: 100%;
  min-height: 25px; height: 25px;
  padding: 10px 15px;

  justify-content: center;
  align-items: center;
  align-content: center;

  border: .5px solid rgba(120, 141, 152, .15);
  /*box-shadow: 0 1.5px 3px 0 rgba(0,0,0,.1);*/

  /*overflow: hidden;*/
  /*white-space: nowrap;*/
  /*border-collapse: collapse;*/
  outline: none;
  user-select: none;
}
.v-records-list-item.is-active {
  /*background: #ffcd70;*/
  /*background: #dadada;*/
  /*background: #e4e4e4; цвет курсора */
  background:#ffcd70; /* цвет курсора */
}
.v-records-list-manager {
  position: absolute;
  top: 40px;
  background: #e9e7df;
}


</style>
